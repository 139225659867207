require("./questions")

getLightbox = (questionID) => {
    const question = questions[questionID]

    switch(question.type){
        case 1:
            return `
            <div class="question">
            <p>${question.question}<br>
                <span class="taskText">${question.task}</span>
            </p>
            </div>
            `;
        
        case 2:
            return `
            <div class="question">
            <p>${question.question}<br>
                <input id="solutionInput">
            </p>
            </div>
            `;

        case 3:
            return `
            <div class="question">
            <p>${question.question}</p>
            </div>
            `;
        case 4:
            return `
            <div class="question">
            <p>${question.question}<br>
                <input id="solutionInput">
            </p>
            </div>
        `;
        case 5:
            return `
            <div class="question">
            <p>${question.question}<br>
                <input id="solutionInput">
            </p>
            </div>
                `;

        
        case 6:
            return `
            <div class="question">
            <p class="wrong">${question.question}</p>
            </div>
            `;
        case 7:
            return `
            <div class="question">
            <p class="right">${question.question}</p>
            </div>
                `;
            
    }
}