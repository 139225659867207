require("./questions")
require("./helper")
require("./lightbox")
require("./rotate")

const basicLightbox = require('basiclightbox')

document.querySelector(".buttonJumanji img").onclick = () => {
    document.querySelector(".buttonJumanji").style.display = "none";
    document.body.classList.add("blur");

    const questionName = getParameter("q");
    if(questionName == null) {
        alert("Unvollständiger URL");
        return;
    }

    const question = questions[questionName];
    if(question == undefined) {
        alert("Frage nicht gefunden");
        return;
    }

    console.log(question.question);
    const instance = basicLightbox.create(getLightbox(questionName));
    instance.show();

    const inputElement = document.querySelector("#solutionInput");
    switch(question.type) {
        case 2:
            inputElement.onkeyup = (e) => {
                if(e.key == "Enter") {
                    instance.close();
                    const insertedText = inputElement.value.toLowerCase();
                    const solution = question.answer.toLowerCase();
                    
                    if(insertedText != solution) {
                        const instance = basicLightbox.create(getLightbox("false"));
                        instance.show();  
                    } else {
                        const instance = basicLightbox.create(getLightbox("true"));
                        instance.show();  
                    }
                }
            }
            break;
        case 4:
            inputElement.onkeyup = (e) => {
                if(e.key == "Enter") {
                    instance.close();
                    const insertedText = inputElement.value.toLowerCase();
                    const solution = [];
                    question.answer.forEach(element => {
                        solution.push(element.toLowerCase())
                    });
                    
                    if(solution.indexOf(insertedText) == -1) {
                        const instance = basicLightbox.create(getLightbox("false"));
                        instance.show();  
                    } else {
                        const instance = basicLightbox.create(getLightbox("true"));
                        instance.show();  
                    }
                }
            }
            break;
        case 5:
            inputElement.onkeyup = (e) => {
                if(e.key == "Enter") {
                    instance.close();
                    const insertedText = inputElement.value.toLowerCase();
                    const solution = question.answer.toLowerCase();
                    
                    if(insertedText != solution) {
                        const instance = basicLightbox.create(getLightbox("false"));
                        instance.show();  
                    } else {
                        const instance = basicLightbox.create(getLightbox("final"));
                        instance.show();  
                    }
                }
            }
            break;
    }
}