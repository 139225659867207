questions = {
    "ecyXF": {
        "question": "Der Wuerfel muss fuenf oder acht ergeben, sonst musst du ewig im Dschungel leben.",
        "task": "Du hast 10 Sekunden Zeit. Schaffst du es eine 5 oder 8 zu wuerfeln, dann gehe 2 Felder vor. Ansonsten gehe 3 Felder zurueck.",
        "type": 1
    },
    "X5ZpP": {
        "question": "Seine Faenge sind scharf, er mag euren Geschmack. Zieht lieber schnell weiter, jetzt aber zack, zack",
        "answer": "Löwe",
        "type": 2
    },
    "wrESc": {
        "question": "Sie wachsen viel schneller als der Bambusstrauch. Gib Acht, sonst jagen sie dich auch.",
        "answer": "fleischfressende Pflanzen",
        "type": 2
    },
    "KDeaB": {
        "question": "Ein kleiner Stich koennte dich jucken, bringt dich zum Niesen, bringt dich zum Zucken.",
        "answer": "Moskito",
        "type": 2
    },
    "MRLBL": {
        "question": "Dies wird keine leichte Mission - Affen behindern die Expedition. – Gehe drei Felder zurueck.",
        "type": 3
    }, 
    "NEcfM": {
        "question": "Sie fliegen nachts, eile bei Zeiten, diese gefluegelten Dinge, nicht viel Freude bereiten.",
        "answer": "Fledermaus",
        "type": 2
    },
    "9deKT": {
        "question": "Pass auf, du verlierst deinen festen Stand! Der Boden fliesst schneller noch als Sand.",
        "answer": "Treibsand",
        "type": 2
    }, 
    "QPYXW": {
        "question": "Eine Regel von Jumanji wurde verletzt, Du wirst um einen Zug zurueckversetzt",
        "type": 3
    },
    "FhLbh": {
        "question": "Es gibt eine Lektion, die sollst du verstehen: Du musst manchmal einen Zug rueckwaertsgehen",
        "type": 3
    },
    "pg4NK": {
        "question": "Fehlt dir eine helfende Hand? Gib Acht, wir haben Acht davon mitgebracht",
        "answer": "Spinnen",
        "type": 2
    },
    "grUQQ": {
        "question": "Halte durch, du darfst nicht wanken, gleich faengt der Boden an zu schwanken",
        "answer": "Erdbeben",
        "type": 2
    },
    "W8c7G": {
        "question": "Der Viertelmond laesst dich nicht ruhn, in deine Lagune treibt er den Monsun.",
        "answer": [
            "Flut",
            "Überschwemmung",
            "Krokodil"
        ],
        "type": 4
    },
    "wHxXc": {
        "question": "Noch ein letztes Wort?",
        "answer": "Jumanji",
        "type": 5
    },
    "false": {
        "question": "Falsch, gehe 2 Felder zurueck.",
        "type": 6
    },
    "true": {
        "question": "Richtig, gehe 2 Feld weiter.",
        "type": 7
    },
    "final": {
        "question": "Ihr habt das Spiel besiegt und euch fur das naechste Level qualifiziert: <span class='normalFont'><a target='_blank' href='https://coord.info/GC5QZ0C'>GC5QZ0C</a> am 27.6. um 9.30 Uhr.</span>",
        "type": 7
    }
}

